@import "../../styles/base";

.specialOffersContainer {
  padding: 0 3rem 3rem;

  @include untilTablet {
    padding: 2rem 0 0 0;
  }
}

.specialOffersLink {
  display: block;
}

.specialOffers {
  background: #00273a url('../../images/special-offers/black-friday/special-offer--background.png') no-repeat;
  box-shadow: inset 0 0 36px 10px rgba(0, 0, 0, .5);
  border-radius: 16px;
  position: relative;
  color: #fff;
  border: none;
  background-size: cover;
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding: 12px 10px;

  &::before {
    content: '';
    background: url('../../images/special-offers/black-friday/special-offer--bolt.png') no-repeat;
    background-size: 24px auto;
    height: 24px;
    width: 20px;
    display: block;
    position: relative;

    @media only screen and (max-width: 1400px) {
      // display: none;
    }
  }
}

.offerDescription {
  display: inline-block;
  text-underline-offset: 4px;
  margin-top: -1px;
  font-weight: normal;
  letter-spacing: 1px;
  position: relative;

  @media only screen and (max-width: 1400px) {
    display: block;
  }
}

.offerCode {
  text-shadow: 0 0 4px #42a5f5, 0 0 4px #42a5f5, 0 0 4px #42a5f5, 0 0 4px #42a5f5;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: .8px;
  text-transform: uppercase;
  padding-left: 5px;
  @media only screen and (max-width: 1400px) {
    
  }
}
