// media query mixins
$mobile: 360px;
$tablet: 768px;
$desktop: 1024px;
$desktopWider: 1078px;

@mixin fromMobile {
  @media only screen and (min-width: $mobile) {
    @content;
  }
}

@mixin untilTablet {
  @media only screen and (max-width: $tablet) {
    @content;
  }
}

@mixin fromTablet {
  @media only screen and (min-width: $tablet) {
    @content;
  }
}

@mixin untilDesktop {
  @media only screen and (max-width: $desktop) {
    @content;
  }
}

@mixin fromDesktop {
  @media only screen and (min-width: $desktop) {
    @content;
  }
}

@mixin fromDesktopWider {
  @media only screen and (min-width: $desktopWider) {
    @content;
  }
}

// colors
$grey: #e4e4e4;
$grey-disabled: #f4f4f4;
$grey-light: #f0f0f0;
$green: #00ad48;
$green-light: #e5f9ee;
$green-dark: #00943d;
$blue: #1a9ee2;
$blue-light: #E3F2FE;
$white: #fff;
$black: #00273b;
$yellow: #ffde02;
$blue-dark: #00496c;
$blue-navey: #0A6AB9;
$blue-royal: #06406F;
$red: #f04326;

// misc
@mixin container {
  padding: 2rem 1rem;
  border: 1px solid $grey;
  clear: both;
  position: relative;
  background-color: $white;

  @include fromMobile {
    padding: 2rem 1.5rem;
  }

  @include fromTablet {
    padding: 3rem 2rem;
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin visuallyHidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

.visuallyHidden {
  @include visuallyHidden;
}

@mixin hideOnMobile {
  @include untilTablet {
    @include visuallyHidden;
  }
}

// loading spinner
@mixin loading() {
  position: relative;
  pointer-events: none;

  // overlay
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white;
    z-index: 3;
    opacity: 0.7;
  }

  // spinner
  &::after {
    background: none;
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    width: 20px !important;
    height: 20px !important;
    margin-top: -10px !important;
    margin-left: -10px !important;
    border-radius: 50% !important;
    display: inline-block !important;
    border: 4px solid $green;
    border-top-color: transparent;
    z-index: 4;
    animation: spinner 0.6s linear infinite;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@mixin btn {
  font-size: $font-size-medium;
  padding: 1.2rem 2rem;
  color: $white;
  background: $green;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  border: solid 2px $green;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  float: right;
  clear: right;
  position: relative;

  &:hover {
    background: $green-dark;
    border-color: $green-dark;
  }

  &::after {
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: inline-block;
    height: 4px;
    position: relative;
    top: 7px;
    left: 0;
    transform: rotate(45deg);
    vertical-align: top;
    width: 4px;
    margin-left: 0.5rem;
  }

  &:disabled {
    background: $grey;
    border-color: $grey;
    cursor: not-allowed;
    color: $white;

    &:hover,
    &:focus {
      background: $grey;
      border-color: $grey;
      color: $white;
    }
  }
}
